*{
    margin: 0px;
    background-color: #FFFFFF;
}
header{
    background-color: rgb(250, 250, 250);
}

header .title{
    text-align: center;
    color:violet;
}

header h2{
    text-align: center;
    color: black;
}
header{
    display: block;
    width: 100%;
}
header .cover{
    display: block;
    width: 98%;
    padding: 20px;
    /* position: absolute; */
}
header .cover .imgProfile,
.imgProfile{
    /* position: absolute; */
    width: 100%;
    height: auto;
    /* padding: 30px; */

}


.navbar .PrincipalNav{
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #FF6525; */
}

.navbar .PrincipalNav .a{
    margin: 15px;
    position: relative;
    font-size: 25px;
    text-decoration: none;
    color: #201112;
    /* background-color: #FF6525; */
}
.navbar .PrincipalNav button{
    /* margin-top: -15px; */
    padding: 15px;
    font-size: 20px;
    border: none;
    color: white;
    background-color: #FF6525;
}
.navbar .Principal button{
    display: none;
}
/* .navbar .PrincipalNav ul{
    display: flex;
    margin-top: 20px;
    
} */
/* .navbar .PrincipalNav ul li{
    padding-left: 35px;
    list-style: none;
} */
/* .navbar .PrincipalNav ul li button{
    margin-top: -15px;
    padding: 20px;
    font-size: 20px;
    border: none;
    color: white;
    background-color: #FF6525;
} */
/* .navbar .PrincipalNav ul li .a{
    font-size: 23px;
    text-decoration: none;
    color: #201112;
} */
.navbar {
    padding-top:50px
}
.Principal .PrincipalTitle{
    color: black;
    /* margin-top: -35%; */
    margin-left: 10%;
    font-size: 40px;
    
}
.mission{
    /* padding: 100px; */
    display: block;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 550px;
    /* height: 200px; */
    padding: 30px;
    /* background-color: aqua; */
    animation-name: example;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    height: 200px;
    box-shadow: 3px 2px 4px 2px gainsboro;
    /* transition-property: all; */
    /* animation-name: example2; */
    /* animation-duration: 18s; */
}

.mission2{
    display: none;
}
.mission3{
   
    /* background-color: aqua; */
    animation-name: example;
    animation-duration: 10s;
    /* transition-property: all; */
    /* border-radius:10%; */
    /* -webkit-border-radius:50%; */
    /* box-shadow: 0px 0px 15px 15px #ec731e;
    -webkit-box-shadow: 0px 0px 15px 15px #ec731e;
    transform: rotate(360deg); */
    /* -webkit-transform: rotate(360deg); */
}
.mission4{
    /* margin: 0 auto; */
   /* padding: 20px; */
    /* background-color: aqua; */
    animation-name: example2;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    /* height: 200px; */
    /* transition-property: all; */

    /* border-radius:10%; */
    /* -webkit-border-radius:50%; */
    /* box-shadow: 0px 0px 15px 15px #ec731e;
    -webkit-box-shadow: 0px 0px 15px 15px #ec731e; */
    /* transform: rotate(360deg); */
    /* -webkit-transform: rotate(360deg); */
}
@keyframes example2{
    /* 0%   {border:10px solid yellow; left:0px; top:0px;transform: rotatex(360deg)} */
    0% {transition: 5s;opacity: 0.2}
    15%  {transition: 5s;opacity: 0.4}
    30%  {transition: 5s;opacity: 0.6}
    60%  {transition: 5s;opacity: 0.8}
    75%  {transition: 5s;opacity: 0.6}
    85%  {transition: 5s;opacity: 0.1}
    100% {transition: 10s;opacity: 0;
        /* -webkit-border-radius:50%; */
        /* box-shadow: 0px 0px 15px 15px #ec731e; */
        /* -webkit-box-shadow: 0px 0px 15px 15px #ec731e; */
        
        /* -webkit-transform: rotateY(150deg); */
    }
}
@keyframes example{
    /* 0%   {background-color:transparent; left:0px; top:0px;} */
    /* 10%  {background-color:yellow; left:200px; top:0px;transform: rotateX(-160deg);} */
    /* 60%  {background-color:transparent; left:200px; top:200px;} */
    /* 75%  {background-color:green; left:0px; top:200px;transition-duration: 0.5s;transition-property: aqua} */
    /* 100% {background-color:red; left:0px; top:0px; */
        /* -webkit-border-radius:50%; */
        /* box-shadow: 0px 0px 15px 15px #ec731e; */
        /* -webkit-box-shadow: 0px 0px 15px 15px #ec731e; */
        0% {border:3px solid #FF6525;left:200px; top:0px;}
        15%  {border:3px solid #A9A9A9; left:200px; top:0px;}
        30%  {border:3px solid #FEF9F2; left:200px; top:0px;}
        60%  {border:3px solid #FF6525; left:200px; top:200px;}
        75%  {border:3px solid #FEF9F2; left:200px; top:200px;}
        85%  {border:3px solid #696969; left:0px; top:200px;}
        100% {border:3px solid #FF6525; left:0px; top:0px;
        /* -webkit-transform: rotateY(150deg); */}
}
.mission h2{
    font-size: 25px;
}
.mission .info p{
    padding-top: 30px;
    display: flex; 
    text-align: center; 
    justify-content: center;
    font-size: 20px;
    font-weight: 400;
}

.footer{
    margin-left: 9%;
    margin-right: 9%; 
    display: flex;
    justify-content:space-evenly;
    border-bottom: 1px solid #E9EAEA;
    /* background-color: #696969; */
    /* color: rgb(54, 54, 54); */
}

.footer .InfoFooter,
.footer .SocialMedias{
    padding: 40px;
}
.footer .InfoFooter h2{
    font-size: 25px;
    color: rgb(54, 54, 54);
}
.footer .InfoFooter p{
    font-size: 20px;
    color: #696969;
}
.package{
    display: flex;
    margin: 9%;
    padding: 70px 70px 40px 70px ;
    justify-content: space-around;
    border-radius: 30px;
    background-color: #FEF9F2;
    
}
.package .package1,
.package .package2,
.package .package3{
    width: 22%;
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 1px 1px 2px 2px #E9EAEA,2px 2px 3px 3px #E9EAEA;
}
.package .package1 .duties,
.package .package2 .duties,
.package .package3 .duties{
    padding: 20px 0px 20px 0px;
    
}
.package .package1 .duties p,
.package .package2 .duties p,
.package .package3 .duties p{
    color: #201112;
    font-size: 16px;
    padding: 10px 0px 10px 0px;
}   
.package .package1 .price h2,
.package .package2 .price h2,
.package .package3 .price h2{
    color: #201112;
    padding-right: 7px;
    font-size: 30px;
}
.package .package1 .price,
.package .package2 .price ,
.package .package3 .price{
    padding-bottom: 20px;
    display: flex;

} 
.package .package1 .price P,
.package .package2 .price P,
.package .package3 .price P{
    color: #c3c3c3;
    padding-top: 10px;
}
.package .package1 h3,
.package .package2 h3,
.package .package3 h3{
    color: #201112;
    font-size: 25px;
    padding-bottom: 10px;
    
}
.package .package1 h4,
.package .package2 h4,
.package .package3 h4{
    font-weight: 400;
    color: #8C8C8C;
    padding-bottom: 20PX;
    border-bottom: 1px solid #F2F5F4;
}

.package .package1 button,
.package .package2 button,
.package .package3 button{
    width: 100%;
    padding: 15px;
    border: 1px solid #FF6525;
    color: black;
    background-color: #FFFFFF;
    border-radius: 10px;
    font-size: 15px;
    text-align: left;
}

.package .package1 button:hover,
.package .package2 button:hover,
.package .package3 button:hover{
    background-color: #FF6525;
    color: white;
}

.UnderFooter{
    margin-left: 9%;
    margin-right: 9%;
    margin-top: 40px ;
    margin-bottom: 50px;
}
.UnderFooter p{
    font-size: 25px;
    color: #201112;
}

/* .infoCompany{
    display: flex;
    justify-content:space-around;
    margin-left: 9%;
    margin-right: 9%;
    margin-top: 60px;
    margin-bottom: 60px;
} */
/* .infoCompany h2{
    padding-top: 40px;
    padding-bottom: 30px;
} */
/* .infoCompany p{
    padding-top: 30px;
    padding-bottom: 30px;
}
.infoCompany h3{
    padding-top: 30px;
} */
.backgroundcontact{
    margin-top: 40px;
    width: 100%;
    height: 700px;
}

.contact{
 display: flex;
 justify-content:center;
 margin-bottom: 200px;
}


.contact .contactone,
.contact .contactwo{
    margin-top: -90px;
    border: solid 1px #c3c3c3;
    width: 200px;
    padding: 90px;
    text-align: center;
    margin-right: 50px;
    box-shadow: 1px 1px 2px 2px #E9EAEA,2px 2px 3px 3px #E9EAEA;
    
}
.contact .contactone h2,
.contact .contactwo h2{
    color: #696969;
    padding-bottom: 30px;
}
.contact .contactone h3,
.contact .contactwo h3{
    padding-bottom: 30px;
    color: #8C8C8C;
}
.contacttitle{
    text-align: center;
    margin-top: -400px;
    margin-bottom: 350px;
    color: white;
    font-size: 50px;
}
.navbar .openNav{
    display: none;
}

.infoCompany .info .title{
    display: flex;
}
/* .countiner-style .slider{
    height: 100%;
} */

@media screen and (max-width:1500px) {
    .navbar .Principal .PrincipalTitle{
        font-size: 30px;
    }
    .navbar .PrincipalNav .a{
        font-size: 20px;
    }
    .navbar .PrincipalNav button{
        font-size: 15px;
    }
}

@media screen and (max-width:1150px){
    .navbar{
        background-color: #FEF9F2;
    }
    .navbar .Principal{
        padding: 30px;
        display: flex;
        justify-content: space-between;
    }
    .navbar .Principal button{
        display: block;
        margin-right: 10%;
        border-radius: 100%;
        color: #FF6525;
        background-color: transparent;
        border: none;
        font-size: 35px;
    }
    .navbar .Principal .PrincipalTitle,
    .navbar .Principal{
        background-color: #FEF9F2;
    }
    .navbar .elements1{
        display: none; 
    }
    .navbar .elements2{
        display: flex;
        position: relative;
        /* top: 60px; */
        background-color: #FF6525;
        width: 100%;
        flex-direction: column;
        height: -webkit-fill-available;
        transition: 0.3s ease all;
    }
    .navbar .elements2 .a{
        padding-bottom: 10px;
        display: flex;
        justify-content: center;
        color: white;
        text-decoration: none;
        background-color: #FF6525;
    }
    
    .navbar .elements2 button{
        margin: 10px;
        border: none;
        background-color: #FF6525;
        color: white;
    }
    
    /* .navbar .Principal button{
        width: 30px;
        height: 24px;
        background-color: white;
        margin-bottom: 5px;
        border-radius: 100%;
        transform-origin: 5px 0px;
        transition: all 0.2s linear;
    } */
    
}
@media screen and (max-width:1000px){
    .package{
        display: grid;
        grid-auto-columns: 100%;
        text-align: center;
    }
    .package .package1,
    .package .package2,
    .package .package3{
        margin-top: 10px;
        width:100%;
    }
    .whatsapp{
        width:40px;
        height:40px;
    }
    .backgroundcontact{
        width: 100%;
        height: 600px;
    }
    .contact .contactone,
    .contact .contactwo{
        padding-top: 40px;
        width: 100px;
        height: 100px;
        text-align: center;
    }
    .contact .contactone h3,
    .contact .contactwo h3{
        font-size: 13px;
    }
}
@media screen and (max-width:900px){
    .mission{
        width: 350px;
        height: 150px;
    }
    .mission h2{
        font-size: 20px;
    }
    .mission .info p{
        padding-top: 30px;
        /* display: flex;  */
        text-align: center; 
        justify-content: center;
        font-size: 15px;
        font-weight: 400;
    }
    .backgroundcontact{
        width: 100%;
        height: 500px;
    }
    .contacttitle{
        margin-top: -300px;
        margin-bottom: 250px;
    }
    .contact .contactone,
    .contact .contactwo{
        margin-top: -90px;
        width: 70px;
        height: 70px;
    }
}
@media screen and (max-width:700px){
    .navbar .Principal .PrincipalTitle{
        font-size: 25px;
    }
    .infoCompany .info .title,
    .infoCompany .info .information1,
    .infoCompany .info .information2{
        font-size: 15px;
    }
    .infoCompany .image{
        width: 100%;
    }
    .contact{
        display: grid;
        justify-items: center;
        width: 100%;
        
    }

    .contact .contactone,
    .contact .contactwo{
        
        margin-top: 0px;
        margin-right: 0px;
        margin-bottom: 30px;
        width: 100%;
        height: 100px;
    }
    .whatsapp{
        width: 25px;
        height: 25px;
    }
    .footer .InfoFooter h2{
        font-size: 20px;
    }
    .footer .InfoFooter p{
        font-size: 15px;
    }
    .UnderFooter p{
        font-size: 20px;
    }
    .countiner-style{
        height: 300px;
    }
    .infoCompany .info .title,
    .infoCompany .info .information1,
    .infoCompany .info .information2{
        font-size: 10px;
    }
    .backgroundcontact{
        height: 400px;
    }
    .contacttitle{
        margin-top: -200px;
        font-size: 30px;
    }
    
}
@media screen and (max-width:500px){
    .contact .contactone,
    .contact .contactwo{
        width: 50%;
        height: 100px;
    }
    .infoCompany .info .title,
    .infoCompany .info .information1,
    .infoCompany .info .information2{
        font-size: 8px;
    }
    .package .package1 .duties p,
    .package .package2 .duties p,
    .package .package3 .duties p{
        color: #201112;
        font-size: 10px;
        padding: 10px 0px 10px 0px;
    }   
    .package .package1 .price h2,
    .package .package2 .price h2,
    .package .package3 .price h2{
        color: #201112;
        padding-right: 7px;
        font-size: 20px;
    }
    .package .package1 h3,
    .package .package2 h3,
    .package .package3 h3{
        color: #201112;
        font-size: 15px;
        padding-bottom: 10px;
        
    }
    .package .package1 h4,
    .package .package2 h4,
    .package .package3 h4{
        font-weight: 400;
        color: #8C8C8C;
        padding-bottom: 20PX;
        font-size: 10;
        border-bottom: 1px solid #F2F5F4;
    }

    .mission{
        width: 200px;
        height: 100px;
    }
    .mission h2{
        font-size: 15px;
    }
    .mission .info p{
        font-size: 10px;
    }
    .backgroundcontact{
        height: 300px;
    }
    .contacttitle{
        margin-top: -150px;
        font-size: 20px;
    }
    .UnderFooter p{
        font-size: 15px;
    }
}
@media screen and (max-width:450px){
    .infoCompany .info .title,
    .infoCompany .info .information1,
    .infoCompany .info .information2{
        font-size: 5px;
    }
    .infoCompany .image{
        width: 250px;
    }
}
@media screen and (max-width:400px){
    .infoCompany {
        margin-left: auto;
        margin-right: auto;
        display: grid;
        justify-items: center;
    }

    .infoCompany .ima{
        display: block;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    }
    .icon{
        width: 60%;
    }
}