.infoCompany{
    display: flex;
    justify-content:space-around;
    margin-left: 9%;
    margin-right: 9%;
    margin-top: 60px;
    margin-bottom: 60px;
}
.infoCompany .info .title{
    display: flex;
    margin-top: 60px;
}
.infoCompany .info .title h2,
.infoCompany .info .information1 h2,
.infoCompany .info .information2 h2{
    margin-left: 20px;
    margin-top: -7px;
}
.infoCompany .info .icon{
    color: #25d366;
}
.infoCompany .info .information1{
    display: flex;
    margin-top: 40px;
}
.infoCompany .info .information2{
    display: flex;
    margin-top: 40px;
}

.footer .SocialMedias .facebook{
    color: blue;
    font-size: 34px;
    margin-right: 10px;
}
.footer .SocialMedias .instagram{
    background:radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
    font-size:30px;
    color:white;
    border-radius: 10px;
    padding: 2.5px;
}
.whatsapp {
    padding: 10px;
    position:fixed;
    width:60px;
    height:60px;
    bottom:40px;
    right:40px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    z-index:100;
  }
  
  .whatsapp-icon {
    margin-top:13px;
    /* color:#FFF; */
  }

@media screen and (max-width:900px){
    .footer .SocialMedias .instagram{
        font-size: 20px;
    }
    .footer .SocialMedias .facebook{
        font-size: 24px;
    }
}
